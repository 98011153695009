window["SHGPOL"]["templates"]["state-summary-component"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " <var>United States</var> ";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "the state of <var>"
    + container.escapeExpression((lookupProperty(helpers,"computed")||(depth0 && lookupProperty(depth0,"computed"))||alias2).call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"state",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":1,"column":100},"end":{"line":1,"column":119}}}),(depth0 != null ? lookupProperty(depth0,"toStateName") : depth0),{"name":"computed","hash":{},"data":data,"loc":{"start":{"line":1,"column":89},"end":{"line":1,"column":133}}}))
    + "</var> ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"eq")||(depth0 && lookupProperty(depth0,"eq"))||alias2).call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"state",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":1,"column":10},"end":{"line":1,"column":29}}}),"US",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":1,"column":6},"end":{"line":1,"column":35}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":147}}})) != null ? stack1 : "");
},"useData":true});;
window["SHGPOL"]["templates"]["progress-bar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " is-offset";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " progress-bar-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"type","hash":{},"data":data,"loc":{"start":{"line":2,"column":52},"end":{"line":2,"column":60}}}) : helper)));
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"not")||(depth0 && lookupProperty(depth0,"not"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"fill") : depth0),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":2,"column":70},"end":{"line":2,"column":80}}}),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":60},"end":{"line":2,"column":100}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return " progress-bar-info";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"percent_text") || (depth0 != null ? lookupProperty(depth0,"percent_text") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"percent_text","hash":{},"data":data,"loc":{"start":{"line":9,"column":26},"end":{"line":9,"column":42}}}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"percent") || (depth0 != null ? lookupProperty(depth0,"percent") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"percent","hash":{},"data":data,"loc":{"start":{"line":9,"column":50},"end":{"line":9,"column":61}}}) : helper)))
    + "%";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"progress progress-component"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"offset") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":39},"end":{"line":1,"column":70}}})) != null ? stack1 : "")
    + "\">\n  <div class=\"progress-bar"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":2,"column":26},"end":{"line":2,"column":107}}})) != null ? stack1 : "")
    + "\" \n       role=\"progressbar\"\n       aria-valuenow=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"percent") || (depth0 != null ? lookupProperty(depth0,"percent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"percent","hash":{},"data":data,"loc":{"start":{"line":4,"column":22},"end":{"line":4,"column":35}}}) : helper)))
    + "\"\n       aria-valuemin=\"0\"\n       aria-valuemax=\"100\"\n       style=\"width: "
    + alias4(((helper = (helper = lookupProperty(helpers,"percent") || (depth0 != null ? lookupProperty(depth0,"percent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"percent","hash":{},"data":data,"loc":{"start":{"line":7,"column":21},"end":{"line":7,"column":32}}}) : helper)))
    + "%; background-color: "
    + alias4(((helper = (helper = lookupProperty(helpers,"fill") || (depth0 != null ? lookupProperty(depth0,"fill") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fill","hash":{},"data":data,"loc":{"start":{"line":7,"column":53},"end":{"line":7,"column":61}}}) : helper)))
    + "; color: "
    + alias4(((helper = (helper = lookupProperty(helpers,"color") || (depth0 != null ? lookupProperty(depth0,"color") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"color","hash":{},"data":data,"loc":{"start":{"line":7,"column":70},"end":{"line":7,"column":79}}}) : helper)))
    + ";\">\n    <span>\n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"percent_text") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":9,"column":69}}})) != null ? stack1 : "")
    + "\n    </span>\n  </div>\n</div>\n";
},"useData":true});;
window["SHGPOL"]["templates"]["policy-actions-component"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  Read more "
    + container.escapeExpression((lookupProperty(helpers,"icon")||(depth0 && lookupProperty(depth0,"icon"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"name":"info-sign"},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":37}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  Getting started "
    + container.escapeExpression((lookupProperty(helpers,"icon")||(depth0 && lookupProperty(depth0,"icon"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"name":"info-sign"},"data":data,"loc":{"start":{"line":5,"column":18},"end":{"line":5,"column":43}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  Guided tour "
    + container.escapeExpression((lookupProperty(helpers,"icon")||(depth0 && lookupProperty(depth0,"icon"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"name":"play-circle"},"data":data,"loc":{"start":{"line":8,"column":14},"end":{"line":8,"column":41}}}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  Print "
    + container.escapeExpression((lookupProperty(helpers,"icon")||(depth0 && lookupProperty(depth0,"icon"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"name":"print"},"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":11,"column":29}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"button")||(depth0 && lookupProperty(depth0,"button"))||alias2).call(alias1,"read_more",{"name":"button","hash":{"class":"header-button","onClick":(depth0 != null ? lookupProperty(depth0,"onReadMore") : depth0)},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"button")||(depth0 && lookupProperty(depth0,"button"))||alias2).call(alias1,"getting_started",{"name":"button","hash":{"class":"header-button","onClick":(depth0 != null ? lookupProperty(depth0,"onGettingStarted") : depth0)},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"button")||(depth0 && lookupProperty(depth0,"button"))||alias2).call(alias1,"start_tour",{"name":"button","hash":{"class":"header-button hidden-xs","onClick":(depth0 != null ? lookupProperty(depth0,"onStartTour") : depth0)},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"button")||(depth0 && lookupProperty(depth0,"button"))||alias2).call(alias1,"print_policy",{"name":"button","hash":{"class":"header-button hidden-xs","onClick":(depth0 != null ? lookupProperty(depth0,"onPrintPolicy") : depth0)},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":0},"end":{"line":12,"column":11}}})) != null ? stack1 : "")
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"share-button") || (depth0 != null ? lookupProperty(depth0,"share-button") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"share-button","hash":{},"data":data,"loc":{"start":{"line":13,"column":0},"end":{"line":13,"column":16}}}) : helper)));
},"useData":true});;
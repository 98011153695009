window["SHGPOL"]["templates"]["explorer-component-print"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias3((lookupProperty(helpers,"render")||(depth0 && lookupProperty(depth0,"render"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"print_views") : depth0)) != null ? lookupProperty(stack1,"prevalence_all") : stack1),{"name":"render","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":37}}}))
    + "\n"
    + alias3((lookupProperty(helpers,"render")||(depth0 && lookupProperty(depth0,"render"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"print_views") : depth0)) != null ? lookupProperty(stack1,"prevalence_18_24") : stack1),{"name":"render","hash":{},"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":39}}}))
    + "\n"
    + alias3((lookupProperty(helpers,"render")||(depth0 && lookupProperty(depth0,"render"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"print_views") : depth0)) != null ? lookupProperty(stack1,"prevalence_25_44") : stack1),{"name":"render","hash":{},"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":3,"column":39}}}))
    + "\n"
    + alias3((lookupProperty(helpers,"render")||(depth0 && lookupProperty(depth0,"render"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"print_views") : depth0)) != null ? lookupProperty(stack1,"prevalence_45_64") : stack1),{"name":"render","hash":{},"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":4,"column":39}}}))
    + "\n"
    + alias3((lookupProperty(helpers,"render")||(depth0 && lookupProperty(depth0,"render"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"print_views") : depth0)) != null ? lookupProperty(stack1,"prevalence_65p") : stack1),{"name":"render","hash":{},"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":5,"column":37}}}))
    + "\n\n"
    + alias3((lookupProperty(helpers,"render")||(depth0 && lookupProperty(depth0,"render"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"print_views") : depth0)) != null ? lookupProperty(stack1,"prevalence_reduction_all") : stack1),{"name":"render","hash":{},"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":7,"column":47}}}))
    + "\n"
    + alias3((lookupProperty(helpers,"render")||(depth0 && lookupProperty(depth0,"render"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"print_views") : depth0)) != null ? lookupProperty(stack1,"prevalence_reduction_18_24") : stack1),{"name":"render","hash":{},"data":data,"loc":{"start":{"line":8,"column":0},"end":{"line":8,"column":49}}}))
    + "\n"
    + alias3((lookupProperty(helpers,"render")||(depth0 && lookupProperty(depth0,"render"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"print_views") : depth0)) != null ? lookupProperty(stack1,"prevalence_reduction_25_44") : stack1),{"name":"render","hash":{},"data":data,"loc":{"start":{"line":9,"column":0},"end":{"line":9,"column":49}}}))
    + "\n"
    + alias3((lookupProperty(helpers,"render")||(depth0 && lookupProperty(depth0,"render"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"print_views") : depth0)) != null ? lookupProperty(stack1,"prevalence_reduction_45_64") : stack1),{"name":"render","hash":{},"data":data,"loc":{"start":{"line":10,"column":0},"end":{"line":10,"column":49}}}))
    + "\n"
    + alias3((lookupProperty(helpers,"render")||(depth0 && lookupProperty(depth0,"render"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"print_views") : depth0)) != null ? lookupProperty(stack1,"prevalence_reduction_65p") : stack1),{"name":"render","hash":{},"data":data,"loc":{"start":{"line":11,"column":0},"end":{"line":11,"column":47}}}))
    + "\n\n"
    + alias3((lookupProperty(helpers,"render")||(depth0 && lookupProperty(depth0,"render"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"views") : depth0)) != null ? lookupProperty(stack1,"deaths_avoided") : stack1),{"name":"render","hash":{},"data":data,"loc":{"start":{"line":13,"column":0},"end":{"line":13,"column":31}}}))
    + "\n\n"
    + alias3((lookupProperty(helpers,"render")||(depth0 && lookupProperty(depth0,"render"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"views") : depth0)) != null ? lookupProperty(stack1,"lys_gained") : stack1),{"name":"render","hash":{},"data":data,"loc":{"start":{"line":15,"column":0},"end":{"line":15,"column":27}}}))
    + "\n";
},"useData":true});;
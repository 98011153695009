window["SHGPOL"]["templates"]["icon-component"] = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg>\n  <use xlink:href=\"#icon-"
    + container.escapeExpression((lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"props") : depth0),"name",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":2,"column":25},"end":{"line":2,"column":45}}}))
    + "\"></use>\n</svg>";
},"useData":true});;
Handlebars.registerPartial('policy-header', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"policy-header row no-padding\">\n  <div id=\"top\" class=\"col-sm-12\">\n    <h1 class=\"header-text\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":3,"column":28},"end":{"line":3,"column":37}}}) : helper)))
    + "</h1>\n    "
    + alias3((lookupProperty(helpers,"policy-actions")||(depth0 && lookupProperty(depth0,"policy-actions"))||alias2).call(alias1,{"name":"policy-actions","hash":{"print":(depth0 != null ? lookupProperty(depth0,"print") : depth0),"touring":(depth0 != null ? lookupProperty(depth0,"touring") : depth0),"getting_started":(depth0 != null ? lookupProperty(depth0,"getting_started") : depth0),"info":(depth0 != null ? lookupProperty(depth0,"info") : depth0)},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":93}}}))
    + "\n  </div>\n</div>";
},"useData":true}));
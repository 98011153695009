window["SHGPOL"]["templates"]["checkbox-component"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " checked";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input type=\"checkbox\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"checked",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":1,"column":28},"end":{"line":1,"column":49}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":22},"end":{"line":1,"column":66}}})) != null ? stack1 : "")
    + " aria-labelledby=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"labelledby") || (depth0 != null ? lookupProperty(depth0,"labelledby") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"labelledby","hash":{},"data":data,"loc":{"start":{"line":1,"column":84},"end":{"line":1,"column":98}}}) : helper)))
    + "\">\n<span class=\"control-indicator\"></span>\n<span class=\"checkbox-label\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":3,"column":33},"end":{"line":3,"column":40}}}) : helper)))
    + "\">"
    + ((stack1 = (lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"label",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":3,"column":42},"end":{"line":3,"column":65}}})) != null ? stack1 : "")
    + "</span>";
},"useData":true});;
window["SHGPOL"]["templates"]["share-button-component"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  Share "
    + container.escapeExpression((lookupProperty(helpers,"icon")||(depth0 && lookupProperty(depth0,"icon"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"name":"share"},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":29}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"button")||(depth0 && lookupProperty(depth0,"button"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"share",{"name":"button","hash":{"class":"header-button share-button hidden-xs","onClick":(depth0 != null ? lookupProperty(depth0,"onShare") : depth0)},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":11}}})) != null ? stack1 : "");
},"useData":true});;
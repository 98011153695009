window["SHGPOL"]["templates"]["range-dropdown-component"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression((lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"props") : depth0),"value",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":10,"column":25}}}))
    + "\n  ";
},"3":function(container,depth0,helpers,partials,data) {
    return "5 years";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button\n  class=\"btn btn-default dropdown-toggle\"\n  type=\"button\"\n  id=\""
    + alias3((lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"id",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":24}}}))
    + "\"\n  data-toggle=\"dropdown\"\n  aria-label=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":6,"column":14},"end":{"line":6,"column":23}}}) : helper)))
    + "\"\n  aria-haspopup=\"true\"\n  aria-expanded=\"true\">\n"
    + ((stack1 = (lookupProperty(helpers,"region")||(depth0 && lookupProperty(depth0,"region"))||alias2).call(alias1,{"name":"region","hash":{"class":"inline","binding":(lookupProperty(helpers,"bound")||(depth0 && lookupProperty(depth0,"bound"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"value",{"name":"bound","hash":{},"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":9,"column":41}}})},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":2},"end":{"line":11,"column":13}}})) != null ? stack1 : "")
    + " <span class=\"caret\"></span>\n</button>\n<div class=\"dropdown-menu range-dropdown-menu\" aria-labelledby=\""
    + alias3((lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"id",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":13,"column":64},"end":{"line":13,"column":82}}}))
    + "\">\n  "
    + alias3((lookupProperty(helpers,"placeholder")||(depth0 && lookupProperty(depth0,"placeholder"))||alias2).call(alias1,"slider",{"name":"placeholder","hash":{},"data":data,"loc":{"start":{"line":14,"column":2},"end":{"line":14,"column":26}}}))
    + "\n  "
    + ((stack1 = (lookupProperty(helpers,"button")||(depth0 && lookupProperty(depth0,"button"))||alias2).call(alias1,{"name":"button","hash":{"onClick":(depth0 != null ? lookupProperty(depth0,"onFiveYear") : depth0)},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":2},"end":{"line":15,"column":50}}})) != null ? stack1 : "")
    + "\n</div>";
},"useData":true});;